import React from "react";
import { BiMenu } from "react-icons/bi";

const NavToggle = ({ setMenuOpen, menuOpen }) => {
  return (
    <div
      className="xs:h-10 xs:w-10 md:h-12 md:w-12 rounded-full shadow-xl bg-white flex justify-center items-center p-1 cursor-pointer"
      onClick={() => {
        setMenuOpen(!menuOpen);
      }}
    >
      <div className="h-content">
        <BiMenu />
      </div>
    </div>
  );
};

export default NavToggle;
