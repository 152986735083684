import React from "react";
import { useMedia } from "react-media";
import ContactContainer from "../../components/ContactContainer/ContactContainer";
import Footer from "../../components/Footer/Footer";
import SignList from "../../components/SignList/SignList";

const MobileContact = () => {
  const fixed = useMedia({ query: "(min-height: 1000px)" });
  return (
    <div className="flex flex-col">
      <div className="flex mx-10  sm:mb-5 items-center md:justify-center">
        <img className="flex-shrink xs:-ml-5 xs:w-32 md:w-1/3 mt-20" src="images/telefoon.webp" alt="telefoon" />
        <div className="flex flex-col">
          <h2 className=" xs:text-5xl md:text-8xl font-display">Laat je </h2>
          <h2 className=" xs:ml-4 md:ml-10s xs:text-5xl md:text-8xl ml-10 font-display">horen!</h2>
        </div>
      </div>
      <div className="flex justify-center">
        <ContactContainer />
      </div>
      <SignList fixed={fixed} />
      <Footer placeAtBottom={true} fixed={true} />
    </div>
  );
};

export default MobileContact;
