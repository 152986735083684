import { AnimatePresence, motion } from "framer-motion";
import React from "react";

const PillButton = ({ title, color }) => {
  return (
    <AnimatePresence>
      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        className={` mt-5 rounded-xl w-min px-3 py-1 xs:ml-1 2xl:ml-2  ${color} cursor-pointer transform hover:scale-110 transition delay-100`}
      >
        <p className="font-body font-bold xs:text-xs md:text-sm xl:text-xs text-white">{title}</p>
      </motion.div>
    </AnimatePresence>
  );
};

export default PillButton;
