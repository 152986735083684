import React from "react";

const PageTitle = ({ firstLine, secondLine, showImage = true, image = "images/bijlessr.webp" }) => {
  return (
    <div className="flex mx-5 xs:mb-5 xl:mb-0 justify-center items-center">
      {showImage && <img className="xs:w-3/12 md:w-2/12 place-self-start  sm:block xl:hidden" src={image} alt="title logo" />}
      <h2 className="text-7xl lg:text-8xl hd:text-8xl font-display mb-10 xs:hidden xl:block">
        {firstLine} {secondLine}
      </h2>
      <div className="flex flex-col sm:ml-1 sm:block xl:hidden">
        <h2 className=" xs:text-5xl  md:text-8xl font-display ">{firstLine} </h2>
        <h2 className="  xs:text-5xl sm:text-6xl md:text-8xl ml-5 md:ml-20 font-display">{secondLine}</h2>
      </div>
    </div>
  );
};

export default PageTitle;
