import React from "react";
import PageTitle from "../../components/PageTitle/PageTitle";

const NotFound = () => {
  return (
    <div>
      <PageTitle firstLine="Oops" />
      <h2 className="font-body text-6xl">Pagina niet gevonden</h2>
    </div>
  );
};

export default NotFound;
