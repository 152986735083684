import { QueryClient, QueryClientProvider } from "react-query";
import { useEffect, useLayoutEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./views/Home/Home";
import Klassen from "./views/Klassen/Klassen";
import Klas from "./views/Klas/Klas";
import Contact from "./views/Contact/Contact";
import OverOns from "./views/OverOns/OverOns";
import NotFound from "./views/NotFound/NotFound";
import NavContainer from "./components/NavContainer/NavContainer";
import { AnimatePresence } from "framer-motion";
import ViewLoader from "./components/ViewLoader/ViewLoader";
import ReactGA from "react-ga4";
import { HelmetProvider } from "react-helmet-async";
import useScrollLock from "./hooks/useScrollLock";

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const queryClient = new QueryClient();

  const [loading, setloading] = useState(false);

  useEffect(() => {
    ReactGA.initialize("G-475E39G53T");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useLayoutEffect(() => {
    setloading(true);
    const timer = setTimeout(() => {
      setloading(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useScrollLock(menuOpen);

  return (
    <>
      {loading ? (
        <ViewLoader />
      ) : (
        <div className={` h-content ${menuOpen && "overflow-hidden"}`}>
          <QueryClientProvider client={queryClient}>
            <HelmetProvider>
              <Router>
                <NavContainer setMenuOpen={setMenuOpen} menuOpen={menuOpen} />

                <AnimatePresence exitBeforeEnter>
                  <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/klassen" component={Klassen} />
                    <Route path="/klas/:klas" exact component={Klas} />
                    <Route path="/contact" exact component={Contact} />
                    <Route path="/ons" exact component={OverOns} />
                    <Route component={NotFound} />
                  </Switch>
                </AnimatePresence>
              </Router>
            </HelmetProvider>
          </QueryClientProvider>
        </div>
      )}
    </>
  );
}

export default App;
