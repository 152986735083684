import React, { useRef } from "react";
import { FaTimesCircle } from "react-icons/fa";
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";
import { motion } from "framer-motion";

const VideoPlayer = ({ setShowVideoPlayer, currentVideo }) => {
  const ref = useRef();

  const source = {
    type: "video",
    sources: [
      {
        src: currentVideo,
        provider: "vimeo",
      },
    ],
  };

  return (
    <motion.div
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="absolute flex justify-center items-center top-0 h-screen w-screen bg-black bg-opacity-50 z-50"
    >
      <div className="flex flex-col w-full md:w-10/12  bg-white shadow-2xl rounded-2xl p-5">
        <div className="self-end  cursor-pointer xs:mb-1 lg:mb-5" onClick={() => setShowVideoPlayer(false)}>
          <FaTimesCircle className="text-red-500 transform hover:scale-110 transition delay-100" size={36} />
        </div>
        <div>
          <Plyr ref={ref} source={source} />
        </div>
      </div>
    </motion.div>
  );
};

export default VideoPlayer;
