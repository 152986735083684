import React from "react";
import { useMedia } from "react-media";
import BijlesLogo from "../../components/BijlesLogo/BijlesLogo";
import ContactContainer from "../../components/ContactContainer/ContactContainer";
import Footer from "../../components/Footer/Footer";
import PageTitle from "../../components/PageTitle/PageTitle";
import SignList from "../../components/SignList/SignList";

const DesktopContact = () => {
  const fixed = useMedia({ query: "(min-height: 760px)" });

  return (
    <div className="h-content">
      <div className="flex flex-col justify-center items-center h-3/4  pt-20">
        <PageTitle firstLine="Laat je" secondLine="horen!" />
        <div className="grid grid-cols-9 w-full">
          <ContactContainer />
          <img
            className="place-self-end  2xl:w-full lg:mt-24 hd:mt-20 hdpi:mt-24   col-start-3 col-end-5 qhd:w-10/12 lg:w-9/12 xl:pt-5 qhd:pt-28 lg:mr-16 qhd:mr-20 row-start-1 z-10"
            src="images/telefoon.webp"
            alt="telefoon"
          />
        </div>
      </div>

      <SignList fixed={fixed} />
      <BijlesLogo />
      <Footer placeAtBottom={true} fixed={true} />
    </div>
  );
};

export default DesktopContact;
