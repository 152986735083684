import React from "react";
import ClassList from "../../components/ClassList/ClassList";
import LessenStats from "../../components/LessenStats/LessenStats";
import Logos from "../../components/Logos/Logos";
import Sign from "../../components/Sign/Sign";
import Footer from "../../components/Footer/Footer";
import SignList from "../../components/SignList/SignList";

const HomeMobile = () => {
  return (
    <div className="flex  flex-col overflow-x-hidden">
      <div className="self-end relative flex-shrink  ">
        <img src="images/hero.webp" className="h-full w-full" alt="fam" />
        <Sign className="absolute top-1/2  w-2/12" sign="plus" />
      </div>
      <div className="mt-4">
        <h1 className="font-display ml-5 xs:text-7xl md:text-8xl lg:text-9xl">Goed</h1>
        <h1 className="font-display xs:text-7xl md:text-8xl lg:text-9xl ml-16 -mt-5">nieuws!</h1>
        <p className="mx-5 font-body md:text-lg lg:text-xl mt-5">
          Samen met De Molen N.V. zorgen wij van Bijles.sr ervoor, dat alle Surinaamse leerlingen op GLO niveau gratis rekenbijles kunnen volgen. In de tussentijd breiden wij ons
          lesmateriaal uit ter voorbereiding op het nieuw schooljaar.
        </p>
      </div>
      <div className="flex flex-col relative  pt-24">
        <Logos mobile />
        <img className="w-full" src="/images/green_blob_mobile.svg" alt="" />
        <div className="absolute xs:top-44  sm:top-56 md:top-1/2  flex flex-col ">
          <div className="flex justify-between items-center ml-5 xs:my-5 my-10 z-10 w-screen">
            <h1 className="font-display text-5xl xs:text-4xl md:text-7xl lg:text-8xl">De Lessen</h1>
            <div className="md:flex-shrink md:w-4/12">
              <Sign className=" xs:w-5/12 sm:w-1/2 md:w-5/12 " sign="is" />
            </div>
          </div>
          <LessenStats mobile />
        </div>
      </div>
      <div className="mx-5 flex flex-col mb-10">
        <div className="self-center mt-10">
          <h3 className=" font-display xs:text-4xl sm:text-6xl md:text-7xl lg:text-9xl">Kies je klas!</h3>
        </div>
        <Sign className="self-end w-2/12 mt-5" sign="min" />

        <ClassList />
      </div>
      <SignList animate />
      <Footer placeAtBottom={true} fixed={true} />
    </div>
  );
};

export default HomeMobile;
