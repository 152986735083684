import React from "react";
import Class from "../Class/Class";

const ClassList = ({ large = false }) => {
  return (
    <div
      className={`h-content w-full xs:mb-24 xl:mb-0 ${
        large ? "xl:w-1/3 xs:w-10/12 qhd:w-1/2" : " xl:w-full hdpi:w-10/12"
      }  grid xs:gap-y-3  grid-cols-2 grid-auto-rows md:grid-cols-3 md:grid-rows-2`}
    >
      <Class className={` ${large ? "qhd:w-48 hd:w-36 xl:w-28 lg:w-36 md:w-36  sm:w-28 xs:w-16" : "qhd:w-36 hd:w-32 xl:w-24 md:w-32  sm:w-28 xs:w-24"} `} classNumber={1} />
      <Class className={` ${large ? "qhd:w-48 hd:w-36 xl:w-28 lg:w-36 md:w-36  sm:w-28 xs:w-16" : "qhd:w-36 hd:w-32 xl:w-24 md:w-32  sm:w-28 xs:w-24"}`} classNumber={2} />
      <Class className={` ${large ? "qhd:w-48 hd:w-36 xl:w-28 lg:w-36 md:w-36  sm:w-28 xs:w-16" : "qhd:w-36 hd:w-32 xl:w-24 md:w-32  sm:w-28 xs:w-24"}`} classNumber={3} />
      <Class className={` ${large ? "qhd:w-48 hd:w-36 xl:w-28 lg:w-36 md:w-36  sm:w-28 xs:w-16" : "qhd:w-36 hd:w-32 xl:w-24 md:w-32  sm:w-28 xs:w-24"}`} classNumber={4} />
      <Class className={` ${large ? "qhd:w-48 hd:w-36 xl:w-28 lg:w-36 md:w-36  sm:w-28 xs:w-16" : "qhd:w-36 hd:w-32 xl:w-24 md:w-32  sm:w-28 xs:w-24"}`} classNumber={5} />
      <Class className={` ${large ? "qhd:w-48 hd:w-36 xl:w-28 lg:w-36 md:w-36  sm:w-28 xs:w-16" : "qhd:w-36 hd:w-32 xl:w-24 md:w-32  sm:w-28 xs:w-24"}`} classNumber={6} />
    </div>
  );
};

export default ClassList;
