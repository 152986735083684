import React from "react";
import Sign from "../Sign/Sign";
import { motion } from "framer-motion";

const SignList = ({ fixed = false, animate = false }) => {
  const variants = {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 1,
      },
    },
  };

  const item = {
    hidden: {
      y: 0,
    },
    show: {
      y: -40,
      transition: {
        repeat: Infinity,
        repeatType: "reverse",
        repeatDelay: 1,
        duration: 0.8,
      },
    },
  };

  return (
    <motion.div
      variants={animate && variants}
      initial="hidden"
      animate="show"
      className={`flex justify-center items-center ${fixed && "absolute xs:bottom-0 xl:bottom-10 qhd:bottom-24 inset-x-0"}`}
    >
      <motion.div variants={animate && item}>
        <Sign className="xs:w-10 sm:w-16 md:w-32 lg:w-20 2k:w-24 hd:w-32 qhd:w-44 xs:mr-5  md:mr-10" sign="maal" />
      </motion.div>
      <motion.div variants={animate && item}>
        <Sign className="xs:w-14 sm:w-16 md:w-32 lg:w-20 2k:w-24 hd:w-32 qhd:w-44 xs:mr-5 md:mr-10" sign="is" />
      </motion.div>
      <motion.div variants={animate && item}>
        <Sign className="xs:w-14 sm:w-16 md:w-32 lg:w-20 2k:w-24 hd:w-32 qhd:w-44 xs:mr-5 md:mr-10" sign="min" />
      </motion.div>
      <motion.div variants={animate && item}>
        <Sign className="xs:w-14 sm:w-16 md:w-32 lg:w-20 2k:w-24 hd:w-32 qhd:w-44" sign="plus" />
      </motion.div>
    </motion.div>
  );
};

export default SignList;
