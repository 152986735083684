import React from "react";

const KwartaalContainer = ({ kwartaal }) => {
  return (
    <div className=" mt-5 rounded-xl w-content px-4  bg-white">
      <p className="font-body font-bold xs:text-sm md:text-base text-oxford-blue">{kwartaal}e Kwartaal</p>
    </div>
  );
};

export default KwartaalContainer;
