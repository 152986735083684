import React from "react";

const Sign = ({ sign, className }) => {
  return (
    <div className="">
      {(() => {
        switch (sign) {
          case "is":
            return <img className={className} src="./images/is.svg" alt="equals sign" />;
          case "maal":
            return <img className={className} src="./images/maal.svg" alt="multiply sign" />;
          case "min":
            return <img className={className} src="./images/min.svg" alt="minus sign" />;
          case "plus":
            return <img className={className} src="./images/plus.svg" alt="plus sign" />;

          default:
            break;
        }
      })()}
    </div>
  );
};

export default Sign;
