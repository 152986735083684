import React from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <div>
      <div className="flex ml-10  text-center  bg-white shadow-xl h-10 rounded-full">
        <NavLink exact activeClassName="text-gray-400" to="/" className="flex items-center px-7  cursor-pointer font-bold hover:text-gray-400 transition delay-100 rounded-full">
          Home
        </NavLink>
        <NavLink activeClassName="text-gray-400" to="/klassen" className="flex items-center px-7 cursor-pointer font-bold hover:text-gray-400 transition delay-100 rounded-full">
          Klassen
        </NavLink>
        <NavLink activeClassName="text-gray-400" to="/ons" className="flex items-center px-7 cursor-pointer font-bold hover:text-gray-400 transition delay-100 rounded-full">
          Over Ons
        </NavLink>
        <NavLink activeClassName="text-gray-400" to="/contact" className="flex items-center px-7 cursor-pointer font-bold hover:text-gray-400 transition delay-100 rounded-full">
          Contact
        </NavLink>
      </div>
    </div>
  );
};

export default Navbar;
