import React, { useState } from "react";
import { FaSpinner } from "react-icons/fa";

const ContactContainer = () => {
  const [busy, setBusy] = useState(false);

  const hanldeSubmit = (e) => {
    e.preventDefault();
    setBusy(true);
    setTimeout(() => {
      setBusy(false);
    }, 4000);
  };

  return (
    <div className="md:flex-shrink xs:w-10/12 md:w-7/12  lg:w-8/12 xl:w-full col-start-4 col-end-7 row-start-1 row-end-2 xs:mb-10">
      <div
        className="w-full h-content bg-gradient-to-br from-blue-ncs-500  to-blue-ncs-400 xs:rounded-tr-3xl xs:rounded-bl-3xl  sm:rounded-tr-3xl sm:rounded-bl-3xl xl:rounded-tr-3xl xl:rounded-bl-3xl hdpi:rounded-bl-4xl hdpi:rounded-tr-4xl qhd:rounded-bl-5xl
      qhd:rounded-tr-5xl xs:pt-16 xs:px-5  sm:pt-16 sm:px-5 px-10 pt-14 hd:px-20 hd:pt-20 xl:px-10 xl:pt-5 hdpi:px-12 hdpi:pt-20 qhd:px-32 qhd:pt-32 pb-5 hd:pb-10"
      >
        <div className="flex flex-col">
          <form className="flex flex-col outline-none" onSubmit={(e) => hanldeSubmit(e)}>
            <input placeholder="Jouw emailadres" className="outline-none rounded-full xs:h-6 md:h-8 lg:h-8 hd:h-8 xs:p-1 p-2 xs:mb-2 mb-5 xs:text-xs md:text-base hd:text-base" />
            <textarea
              placeholder="Typ jouw bericht hier"
              className="outline-none resize-none rounded-2xl p-2 xs:text-xs md:text-base hd:text-base xs:h-36 sm:h-24 md:h-32 lg:h-32 hd:h-36 qhd:h-64"
            ></textarea>
            <button
              type="submit"
              className="outline-none place-self-center -mt-3 bg-green-pigment w-min px-6 py-1 xs:text-sm md:text-base qhd:text-3xl font-medium text-white rounded-2xl z-10 transition delay-500"
            >
              {busy ? <FaSpinner className="animate-spin" /> : "Verzenden"}
            </button>
          </form>
          <div className="flex flex-col items-center font-body xs:text-xs sm:text-xs md:text-base hd:text-lg qhd:text-2xl text-white xs:mt-4 sm:mt-4 xl:mt-5 :mt-10">
            <p>Mozartstraat 26 (maretraite)</p>
            <p>Paramaribo - Suriname</p>
            <p>
              Tel: <a href="tel:+5978989404">+5978989404</a>
            </p>
            <a href="mail:info@bijles.sr">info@bijles.sr</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactContainer;
