import React, { useEffect, useState } from "react";
import { useMedia } from "react-media";
import MobileKlas from "./MobileKlas";
import DesktopKlas from "./DesktopKlas";

import { useQuery } from "react-query";
import { fetchVideos } from "../../services/videos";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import useEscape from "../../hooks/useEscape";
import { AnimatePresence } from "framer-motion";
import ViewContainer from "../../components/ViewContainer/ViewContainer";
import useScrollLock from "../../hooks/useScrollLock";

const Klas = ({ match, location, history }) => {
  const klas = match.params.klas;

  const isSmallScreen = useMedia({ query: "(max-width: 1099px)" });

  const [kwartaal, setKwartaal] = useState(1);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const { data, isLoading, isError } = useQuery(["videos", klas, kwartaal], fetchVideos, { refetchOnWindowFocus: false });

  useEffect(() => {
    if (currentVideo) {
      history.push(`/klas/${klas}`);
      setShowVideoPlayer(true);
    }
  }, [currentVideo, klas, history]);

  useEffect(() => {
    if (new URLSearchParams(location.search).get("videoId") !== null) {
      setCurrentVideo(new URLSearchParams(location.search).get("videoId"));
    }
    if (new URLSearchParams(location.search).get("kwartaal") !== null) {
      setKwartaal(parseInt(new URLSearchParams(location.search).get("kwartaal")));
    }
  }, [location]);

  useEscape(() => {
    setShowVideoPlayer(false);
    setCurrentVideo("");
  });

  useScrollLock(showVideoPlayer);

  return (
    <ViewContainer title={`Klas ${klas}`}>
      {isSmallScreen ? (
        <MobileKlas
          setCurrentVideo={setCurrentVideo}
          setShowVideoPlayer={setShowVideoPlayer}
          showVideoPlayer={showVideoPlayer}
          klas={klas}
          setKwartaal={setKwartaal}
          kwartaal={kwartaal}
          videos={data}
          videosLoading={isLoading}
          videosError={isError}
        />
      ) : (
        <DesktopKlas
          setCurrentVideo={setCurrentVideo}
          setShowVideoPlayer={setShowVideoPlayer}
          showVideoPlayer={showVideoPlayer}
          klas={klas}
          setKwartaal={setKwartaal}
          kwartaal={kwartaal}
          videos={data}
          videosLoading={isLoading}
          videosError={isError}
        />
      )}
      <AnimatePresence>{showVideoPlayer && <VideoPlayer setShowVideoPlayer={setShowVideoPlayer} showVideoPlayer={showVideoPlayer} currentVideo={currentVideo} />}</AnimatePresence>
    </ViewContainer>
  );
};

export default Klas;
