export const fetchVideos = async ({ queryKey }) => {
  const klas = queryKey[1] || "";
  const kwartaal = queryKey[2] || "";
  const res = await fetch(`https://fase01.bijles.sr/public/api/videos/folders/${klas}/${kwartaal}`);

  if (!res.ok) {
    throw new Error();
  }

  try {
    return res.json();
  } catch (error) {
    throw new Error();
  }
};

export const searchVideos = async ({ queryKey }) => {
  const query = queryKey[1] || "";
  if (query !== "") {
    const res = await fetch(`https://cms.bijles.sr/public/api/videos/search/${query}?per_page=5`);
    return res.json();
  }
  return null;
};
