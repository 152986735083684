import { AnimatePresence } from "framer-motion";
import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import useOutsideClick from "../../hooks/useOutsideClick";
import { searchVideos } from "../../services/videos";
import SearchInput from "../SearchInput/SearchInput";
import SearchList from "../SearchList/SearchList";

const SearchContainer = () => {
  const [query, setQuery] = useState("");

  const [isSearching, setIsSearching] = useState(false);
  const { data, isLoading } = useQuery(["search", query], searchVideos);
  const ref = useRef();
  useOutsideClick(ref, () => {
    if (isSearching) {
      setIsSearching(false);
    }
  });

  const handleOnInputChange = (e) => {
    const query = e.target.value;
    setQuery(query);
  };

  return (
    <div ref={ref}>
      <SearchInput setIsSearching={setIsSearching} onChange={handleOnInputChange} />
      <AnimatePresence>{isSearching && <SearchList setIsSearching={setIsSearching} isLoading={isLoading} data={data} />}</AnimatePresence>
    </div>
  );
};

export default SearchContainer;
