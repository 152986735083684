import React from "react";
import { useMedia } from "react-media";
import ViewContainer from "../../components/ViewContainer/ViewContainer";
import DesktopContact from "./DesktopContact";
import MobileContact from "./MobileContact";

const Contact = () => {
  const isSmallScreen = useMedia({ query: "(max-width: 1099px)" });
  return <ViewContainer title="Contact">{isSmallScreen ? <MobileContact /> : <DesktopContact />}</ViewContainer>;
};

export default Contact;
