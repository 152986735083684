import React from "react";
import { useMedia } from "react-media";
import { AnimatePresence } from "framer-motion";

import SearchContainer from "../SearchContainer/SearchContainer";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import NavToggle from "../NavToggle/NavToggle";

const NavContainer = ({ menuOpen, setMenuOpen }) => {
  const isSmallScreen = useMedia({ query: "(max-width: 1024px)" });

  return (
    <>
      <div className="flex justify-between items-center fixed w-screen h-content px-5 mt-5 z-50">
        {isSmallScreen ? <NavToggle setMenuOpen={setMenuOpen} menuOpen={menuOpen} /> : <Navbar />}
        <SearchContainer />
      </div>
      <AnimatePresence>{menuOpen && <Sidebar setMenuOpen={setMenuOpen} menuOpen={menuOpen} />}</AnimatePresence>
    </>
  );
};

export default NavContainer;
