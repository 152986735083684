import React from "react";
import Class from "../../components/Class/Class";
import Footer from "../../components/Footer/Footer";
import KwartaalContainer from "../../components/KwartaalContainer/KwartaalContainer";
import KwartaalSelector from "../../components/KwartaalSelector/KwartaalSelector";
import VideosList from "../../components/VideosList/VideosList";
import BijlesLogo from "../../components/BijlesLogo/BijlesLogo";
const DesktopKlas = ({ setCurrentVideo, klas, kwartaal, setKwartaal, setShowVideoPlayer, videos, videosLoading, videosError }) => {
  return (
    <div className="h-screen">
      <div className="grid grid-cols-6 pt-20 mx-20 ">
        <KwartaalSelector setKwartaal={setKwartaal} kwartaal={kwartaal} />
        <div className="col-span-4 place-self-center ">
          <div className="flex ">
            <div className="flex flex-col items-start justify-center">
              <h2 className=" xl:text-7xl hdpi:text-8xl hd:text-9xl font-display mr-10">De lessen van klas</h2>
              <KwartaalContainer kwartaal={kwartaal} />
            </div>
            <Class className={`qhd:w-48 xl:w-36`} classNumber={klas} />
          </div>
        </div>
        <div></div>
      </div>
      <VideosList videosLoading={videosLoading} videosError={videosError} setCurrentVideo={setCurrentVideo} videos={videos} setShowVideoPlayer={setShowVideoPlayer} />
      <BijlesLogo />
      <Footer placeAtBottom={true} />
    </div>
  );
};

export default DesktopKlas;
