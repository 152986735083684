import React from "react";
import { useMedia } from "react-media";
import Footer from "../../components/Footer/Footer";
import PageTitle from "../../components/PageTitle/PageTitle";
import SignList from "../../components/SignList/SignList";
import ViewContainer from "../../components/ViewContainer/ViewContainer";
import "./OverOns.css";

const OverOns = () => {
  const fixed = useMedia({ query: "(min-height: 699px)" });
  return (
    <ViewContainer className="flex flex-col " title="Over Ons">
      <div className="flex flex-col items-center pt-20 xs:mb-5 md:mb-10">
        <PageTitle firstLine="Over ons" />
        <div id="overOnsContainer" className="w-full flex flex-col justify-center items-center  overflow-hidden xs:pt-10 xs:pb-3 lg:pt-10 lg:pb-3 qhd:pt-32 qhd:pb-8">
          <div className="xs:w-10/12 md:w-6/12 lg:w-1/2 2xl:w-1/3">
            <p className="font-body text-white xs:text-sm md:text-base 2xl:text-lg qhd:text-xl">
              Bijles.sr is een dienst van The Key Fintech &amp; Digital Services N.V. Ons hoofddoel is het creëren en aanbieden van digitale platformen en diensten ter bevordering
              van burger-educatie, ondernemerschap en duurzame ontwikkeling. Op deze wijze willen wij een substantiële bijdrage leveren aan het moderniseren (digitaliseren) van ons
              land.
            </p>
            <p className="font-body text-white xs:text-sm  md:text-base 2xl:text-lg qhd:text-xl xs:mt-5  2xl:mt-10">
              Bijles.sr is in 2018 gestart met het aanbieden van GLO (lager) onderwijs rekenbijlessen. Vanaf juni 2021 bieden wij 267 video's waarin de moeilijkste reken onderdelen
              binnen het GLO-onderwijs behandeld worden. Ook zullen wij regelmatig ons platform updaten met meer vakken en meer lessen.
            </p>
            <p className="self-end font-display  text-right text-white xs:text-base md:text-xl md:text-lg  xl:text-2xl hdpi:text-3xl hd:text-xl qhd:text-3xl xs:mt-5 lg:mt-8 2xl:mt-20">
              Bij de les & de les erbij!
            </p>
            <div className="flex justify-center">
              <img
                className="xs:hidden xl:block  transform xl:-translate-y-12  hd:-translate-y-16 qhd:-translate-y-20 absolute xl:w-20 xl:w-20  hd:w-24 hd:h-24 qhd:w-36  qhd:h-36"
                src="/images/bijlessr.webp"
                alt="De Molen"
              />
            </div>
          </div>
        </div>
      </div>

      <SignList fixed={fixed} />
      <Footer placeAtBottom={true} fixed={true} />
    </ViewContainer>
  );
};

export default OverOns;
