import { motion } from "framer-motion";
import React from "react";
import { FaSpinner } from "react-icons/fa";

const Loader = ({ size = 72 }) => {
  return (
    <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }} className="h-full w-full flex p-2 items-center justify-center">
      <FaSpinner className="animate-spin text-green-pigment" size={size} />
    </motion.div>
  );
};

export default Loader;
