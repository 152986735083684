import { motion } from "framer-motion";
import React from "react";
import Head from "../../helmet/Head/Head";

const ViewContainer = ({ children, title }) => {
  const pageTransition = {
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  return (
    <motion.div variants={pageTransition} animate="in" initial="out" exit="out">
      <Head title={title} />

      {children}
    </motion.div>
  );
};

export default ViewContainer;
