import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { AiFillPlayCircle } from "react-icons/ai";
const VideoThumbnail = ({ image }) => {
  const [showOverlay, setshowOverlay] = useState(false);

  return (
    <motion.svg
      onHoverStart={() => {
        setshowOverlay(true);
      }}
      onHoverEnd={() => {
        setshowOverlay(false);
      }}
      whileHover={{ scale: 1.05 }}
      initial={{ scale: 1.0 }}
      transition={{ duration: 0.2, ease: "easeIn" }}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 453.5 255.1"
      xmlSpace="preserve"
    >
      <g>
        <image x="34" y="23" id="backImage" xlinkHref={image} width="85%" height="82%" preserveAspectRatio="none" />
        <AnimatePresence exitBeforeEnter>
          {showOverlay && (
            <>
              <motion.rect animate={{ opacity: 0.5 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }} x="34" y="23" width="85%" height="82%" opacity="50%" />
              <motion.g animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }}>
                <AiFillPlayCircle x="45%" y="42%" size="48" color="#000" />
              </motion.g>
            </>
          )}
        </AnimatePresence>

        <rect id="XMLID_3_" x="25.2" y="6.3" transform="matrix(1 6.261081e-03 -6.261081e-03 1 0.7993 -0.1864)" width="10" height="242.6" />
        <rect id="XMLID_6_" x="223" y="-190.3" transform="matrix(-6.261076e-03 1 -1 -6.261076e-03 249.0632 -208.2593)" width="10" height="419.8" />
        <rect id="XMLID_8_" x="219.4" y="26.9" transform="matrix(-6.261074e-03 1 -1 -6.261074e-03 461.5118 12.8162)" width="10" height="419.8" />
        <rect id="XMLID_5_" x="417.8" y="6.3" transform="matrix(1 6.261078e-03 -6.261078e-03 1 0.8113 -2.6449)" width="10" height="242.6" />
        <rect id="XMLID_5_" x="417.8" y="6.3" transform="matrix(1 6.261078e-03 -6.261078e-03 1 0.8113 -2.6449)" width="10" height="242.6" />
      </g>
    </motion.svg>
  );
};

export default VideoThumbnail;
