import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import Loader from "../Loader/Loader";
import SearchItem from "../SearchItem/SearchItem";

const SearchList = ({ isLoading, data, setIsSearching }) => {
  return (
    <motion.div
      animate={{ opacity: 1, x: 0 }}
      initial={{ opacity: 0, x: 300 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="h-content rounded-2xl w-72 bg-white fixed right-0 mt-5 mr-2  z-50 shadow-xl"
    >
      <div className="flex flex-col  ">
        <AnimatePresence>
          {isLoading ? (
            <Loader size={48} />
          ) : data && data.body.total === 0 ? (
            <motion.div
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
              className=" xs:text-lg md:text-xl truncate xs:py-4 xs:px-5    border-gray-100 cursor-pointer font-bold"
            >
              <p className="truncate">Geen data</p>
            </motion.div>
          ) : (
            data &&
            data.body.data.map((item, index) => {
              return <SearchItem key={index} item={item} setIsSearching={setIsSearching} />;
            })
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default SearchList;
