import { useEffect } from "react";

const useScrollLock = (value) => {
  useEffect(() => {
    value && (document.body.style.overflowY = "hidden");
    !value && (document.body.style.overflowY = "unset");
  }, [value]);
};

export default useScrollLock;
