import React from "react";
import { useMedia } from "react-media";
import BijlesLogo from "../../components/BijlesLogo/BijlesLogo";
import ClassList from "../../components/ClassList/ClassList";
import Footer from "../../components/Footer/Footer";
import PageTitle from "../../components/PageTitle/PageTitle";
import SignList from "../../components/SignList/SignList";
import ViewContainer from "../../components/ViewContainer/ViewContainer";

const Klassen = () => {
  const fixed = useMedia({ query: "(min-height: 760px)" });

  return (
    <ViewContainer title="Klassen">
      <div className="flex flex-col h-full items-center lg:justify-center lg:h-full xs:pt-20">
        <PageTitle firstLine="Kies je" secondLine="klas!" />
        <ClassList large />
      </div>
      <SignList fixed={fixed} />
      <BijlesLogo />
      <Footer placeAtBottom fixed />
    </ViewContainer>
  );
};

export default Klassen;
