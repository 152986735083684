import React from "react";
import Class from "../../components/Class/Class";
import Footer from "../../components/Footer/Footer";
import KwartaalContainer from "../../components/KwartaalContainer/KwartaalContainer";
import KwartaalSelector from "../../components/KwartaalSelector/KwartaalSelector";
import VideosList from "../../components/VideosList/VideosList";
import { NavLink } from "react-router-dom";

const MobileKlas = ({ klas, kwartaal, setKwartaal, showVideoPlayer, setShowVideoPlayer, setCurrentVideo, videos, videosLoading, videosError }) => {
  return (
    <div className="h-full">
      <div className="flex flex-col mx-5 pt-20">
        <div className="flex xs:justify-between md:justify-center">
          <NavLink exact to="/">
            <img className="flex-shrink w-20 h-20 xs:place-self-end md:place-self-start  md:mr-5" src="/images/bijlessr.webp" alt="De Molen" />
          </NavLink>
          <div>
            <h2 className="font-display text-5xl md:text-7xl">De lessen</h2>
            <div className="flex justify-between items-center">
              <h2 className="font-display text-5xl xs:text-4xl sm:text-5xl md:text-7xl md:-ml-16 mr-5">van klas </h2>
              <Class className={`xs:w-12 md:w-20`} classNumber={klas} />
            </div>
          </div>
        </div>
        <div className="flex justify-between items-end mt-5">
          <KwartaalSelector setKwartaal={setKwartaal} kwartaal={kwartaal} />
          <KwartaalContainer kwartaal={kwartaal} />
        </div>
      </div>
      <VideosList videosLoading={videosLoading} videosError={videosError} setCurrentVideo={setCurrentVideo} videos={videos} setShowVideoPlayer={setShowVideoPlayer} />
      <Footer placeAtBottom fixed />
    </div>
  );
};

export default MobileKlas;
