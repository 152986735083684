import React from "react";

const KwartaalSelector = ({ kwartaal, setKwartaal }) => {
  return (
    <div className="flex flex-col justify-center h-full">
      <p className="font-body xs:text-xl  mb-1 font-bold">Kwartaal</p>
      <div className="flex">
        <p
          onClick={() => {
            setKwartaal(1);
          }}
          className={`${
            kwartaal === 1 && "bg-sinopia"
          } flex xs:h-6 lg:h-8 justify-center items-center xs:mr-2 lg:mr-3 xs:p-2 lg:p-3  font-bold xs:text-xs lg:text-sm rounded-full bg-green-pigment text-white cursor-pointer transform hover:scale-125 hover:bg-sinopia hover:text-white transition duration-500`}
        >
          1
        </p>
        <p
          onClick={() => {
            setKwartaal(2);
          }}
          className={`${
            kwartaal === 2 && "bg-sinopia"
          } flex xs:h-6 lg:h-8 justify-center items-center xs:mr-2 lg:mr-3 xs:p-2 lg:p-3  font-bold xs:text-xs lg:text-sm rounded-full bg-green-pigment text-white cursor-pointer transform hover:scale-125 hover:bg-sinopia hover:text-white transition duration-500`}
        >
          2
        </p>
        <p
          onClick={() => {
            setKwartaal(3);
          }}
          className={`${
            kwartaal === 3 && "bg-sinopia"
          } flex xs:h-6 lg:h-8 justify-center items-center xs:mr-2 lg:mr-3 xs:p-2 lg:p-3  font-bold xs:text-xs lg:text-sm rounded-full bg-green-pigment text-white cursor-pointer transform hover:scale-125 hover:bg-sinopia hover:text-white transition duration-500`}
        >
          3
        </p>
      </div>
    </div>
  );
};

export default KwartaalSelector;
