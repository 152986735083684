import React from "react";

const BijlesLogo = ({ className = "absolute flex bottom-20 left-20 qhd:left-32 xs:hidden xl:block z-40" }) => {
  return (
    <div className={className}>
      <img className=" w-36 h-36 qhd:w-72 qhd:h-72 z-40" src="/images/bijlessr.webp" alt="De Molen" />
    </div>
  );
};

export default BijlesLogo;
