import React from "react";
import { BiSearchAlt2 } from "react-icons/bi";

const SearchInput = ({ setIsSearching, onChange }) => {
  return (
    <div className="  h-10 font-body px-2 bg-white rounded-full shadow-xl flex items-center overflow-hidden">
      <input
        onFocus={() => setIsSearching(true)}
        onKeyUp={(e) => onChange(e)}
        className=" outline-none h-10 rounded-full font-bold xs:text-xs sm:text-sm lg:text-base"
        placeholder="Zoek een onderdeel"
      ></input>
      <BiSearchAlt2 />
    </div>
  );
};

export default SearchInput;
