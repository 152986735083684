import React from "react";
import ClassList from "../../components/ClassList/ClassList";
import Hero from "../../components/Hero/Hero";
import LessenStats from "../../components/LessenStats/LessenStats";
import Logos from "../../components/Logos/Logos";
import Sign from "../../components/Sign/Sign";
import Footer from "../../components/Footer/Footer";

const HomeDesktop = () => {
  return (
    <div className="relative w-screen ">
      <Hero />
      <div className="absolute -bottom-1/2 qhd:-bottom-1/2 w-screen overflow-hidden  flex">
        <div className="flex-shrink w-1/2	z-index-10">
          <div className="relative">
            <Sign className="absolute top-5 right-1/2 xl:right-36  hd:right-64 w-32 hd:w-44 animate-scale" sign="min" />
            <img className="relative w-full" src="/images/green_blob.svg" alt="green curve" />
            <div className="absolute left-10 xl:top-28 hd:top-1/4  top-0 z-20">
              <h1 className="font-display xl:text-5xl lg:text-6xl hd:text-8xl">De Lessen</h1>
              <LessenStats />
            </div>
            <Sign className="absolute top-1/2 right-0 w-32 qhd:w-44 animate-scale" sign="is" />
          </div>
        </div>
        <div className="flex-1 place-self-center mt-28 hdpi:mt-36 hd:mt-56 qhd:mt-72">
          <div className="flex justify-center items-center">
            <div className="flex-shrink w-full hdpi:w-7/12  hd:w-8/12 qhd:w-1/3">
              <h3 className="font-display text-7xl xl:text-8xl hd:text-9xl mr-3">Kies je klas</h3>
            </div>
            <ClassList />
          </div>
        </div>
      </div>
      <Sign className="absolute -bottom-72 hd:-bottom-96 qhd:-bottom-1/3 left-1/3 w-32 qhd:w-40 animate-scale" sign="maal" />
      <Logos />
      <Footer />
    </div>
  );
};

export default HomeDesktop;
