import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useQuery } from "react-query";

import { fetchDocuments } from "../../services/documents";
import PillButton from "../PillButton/PillButton";
import VideoThumbnail from "../VideoThumbnail/VideoThumbnail";

const Video = ({ setCurrentVideo, video, setShowVideoPlayer }) => {
  const videoId = video.uri.split("/")[2].trim();
  const { data } = useQuery(["documents", videoId], fetchDocuments, { refetchOnWindowFocus: false });

  return (
    <AnimatePresence>
      <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }} className="flex flex-col transform ">
        <div
          onClick={() => {
            setShowVideoPlayer(true);
            setCurrentVideo(videoId);
          }}
          className="flex flex-col cursor-pointer"
        >
          <VideoThumbnail image={video.pictures.sizes[2].link} />
          <h5 className="font-body font-bold ml-2 xs:text-sm xs:pr-2 md:pr-0">{video.name}</h5>
        </div>
        <div className="flex h-full  items-end">
          {data && data.sommen && (
            <a href={data.sommen} target="_blank" rel="noreferrer">
              <PillButton title="Sommen" color="bg-sinopia" />
            </a>
          )}
          {data && data.antwoorden && (
            <a href={data.antwoorden} target="_blank" rel="noreferrer">
              <PillButton title="Antwoorden" color="bg-green-ryb" />
            </a>
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Video;
