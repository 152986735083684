import React from "react";

const LessenStats = ({ mobile = false }) => {
  return (
    <div>
      <div className={`grid grid-cols-4 xs:grid-cols-6 grid-rows-3 gap-x-2 qhd:gap-x-6   qhd:mt-5`}>
        <div className="place-self-end md:pr-4">
          <p className={`place-self-end font-body xs:text-xl md:text-5xl ${mobile ? "lg:text-5xl" : "lg:text-4xl"} hd:text-5xl qhd:text-6xl`}>267</p>
        </div>
        <div className="flex col-span-5 ">
          <p className={`font-body xs:text-xl md:text-5xl ${mobile ? "lg:text-5xl" : "lg:text-4xl"} hd:text-5xl qhd:text-6xl`}>Onderdelen</p>
          <div className="h-content ml-1  rounded-xl w-content px-2 bg-uranian-blue place-self-center ">
            <p className="font-body xs:text-xs md:text-base lg:text-sm font-bold   hd:text-sm">opgesteld door</p>
          </div>
        </div>
        <div className="place-self-end md:pr-4">
          <p className={`place-self-end font-body xs:text-xl md:text-5xl ${mobile ? "lg:text-5xl" : "lg:text-4xl"} hd:text-5xl qhd:text-6xl`}>9</p>
        </div>
        <div className="flex col-span-5 ">
          <p className={`font-body xs:text-xl md:text-5xl ${mobile ? "lg:text-5xl" : "lg:text-4xl"} hd:text-5xl qhd:text-6xl`}>Leerkrachten</p>
          <div className="h-content w-32 ml-1 rounded-xl  pl-2 bg-key-lime place-self-center ">
            <p className="font-body xs:text-xs md:text-base lg:text-sm font-bold  hd:text-sm">gescreened door</p>
          </div>
        </div>
        <div className="place-self-end md:pr-4">
          <p className={`font-body xs:text-xl md:text-5xl ${mobile ? "lg:text-5xl" : "lg:text-4xl"} hd:text-5xl qhd:text-6xl`}>2</p>
        </div>
        <div className="col-span-5 ">
          <p className={`font-body xs:text-xl md:text-5xl ${mobile ? "lg:text-5xl" : "lg:text-4xl"} hd:text-5xl qhd:text-6xl`}>Gepensioneerden</p>
        </div>
      </div>
    </div>
  );
};

export default LessenStats;
