import React from "react";
import Sign from "../Sign/Sign";

const Hero = () => {
  return (
    <div className="flex justify-between z-10">
      <div className="flex-shrink w-5/12 h-16	relative">
        <h1 className="font-display  ml-16 mt-28 text-7xl xl:text-8xl hd:text-9xl qhd:mt-40 ">Goed</h1>
        <h1 className="font-display text-7xl  xl:ml-24 xl:text-8xl  hd:text-9xl lg:ml-16 hd:ml-28 qhd:ml-36 lg:-mt-5 hdpi:-mt-5 hd:-mt-10">nieuws!</h1>
        <p className="font-body lg:text-sm xl:text-base hdpi:tetx-xl hd:text-2xl hd:w-10/12 mt-10 lg:ml-5  xl:ml-16  ">
          Samen met De Molen N.V. zorgen wij van Bijles.sr ervoor, dat alle Surinaamse leerlingen op GLO niveau gratis rekenbijles kunnen volgen. In de tussentijd breiden wij ons
          lesmateriaal uit ter voorbereiding op het nieuw schooljaar.
        </p>
        <Sign
          className="absolute right-0 top-24 xl:right-0 hdpi:right-20  hd:right-44   qhd:left-1/2 qhd:top-36 lg:w-16 xl:w-20  2k:w-24 hd:w-28 qhd:w-32 animate-scale"
          sign="plus"
        />
      </div>
      <div className="flex-auto ">
        <img className="w-full h-full  max-w-screen-qhd" id="family-portrait" src="./images/hero.webp" alt="fam" />
      </div>
    </div>
  );
};

export default Hero;
