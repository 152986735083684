import { motion } from "framer-motion";
import React from "react";
import { NavLink } from "react-router-dom";

const SearchItem = ({ item, setIsSearching }) => {
  let klas = "";
  let kwartaal = "";
  let videoId = "";
  if (item.parent_folder) {
    const folder = item.parent_folder.name.split(" ");
    videoId = item.uri.split("/")[2].trim();
    klas = folder[1];
    kwartaal = folder[4];
  }

  return (
    <NavLink
      onClick={() => {
        setIsSearching(false);
      }}
      to={klas !== "" ? `/klas/${klas}?kwartaal=${kwartaal}&videoId=${videoId}` : "/"}
    >
      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        className=" xs:text-lg md:text-xl truncate xs:py-4 xs:px-5    border-gray-100 cursor-pointer font-bold hover:bg-black hover:text-white transition delay-50"
      >
        <p className="truncate">{item.name}</p>
        <sub className="text-gray-400 text-xs">{item.parent_folder ? item.parent_folder.name : "Geen klas"}</sub>
      </motion.div>
    </NavLink>
  );
};

export default SearchItem;
