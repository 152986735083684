import HomeDesktop from "./HomeDesktop";
import { useMedia } from "react-media";
import HomeMobile from "./HomeMobile";
import ViewContainer from "../../components/ViewContainer/ViewContainer";
const Home = () => {
  const isSmallScreen = useMedia({ query: "(max-width: 1199px)" });
  return <ViewContainer title="Home">{isSmallScreen ? <HomeMobile /> : <HomeDesktop />}</ViewContainer>;
};

export default Home;
