import { motion } from "framer-motion";
import React from "react";

const ViewLoader = () => {
  return (
    <motion.div className="flex justify-center items-center w-screen h-screen  bg-white text-9xl">
      <video autoPlay loop muted playsInline>
        <source src="/images/loading-bee.mp4" type="video/mp4" />
        <source src="/images/loading-bee.webm" type="video/webm" />
      </video>
    </motion.div>
  );
};

export default ViewLoader;
