import React from "react";

const Logos = ({ mobile = false }) => {
  return (
    <div
      className={`absolute flex justify-center items-starts ${
        mobile ? " xs:top-10 md:top-10 lg:left-64" : "lg:left-0"
      } xs:left-5 sm:left-14 md:left-32   xl:-bottom-72  hd:-bottom-96 qhd:-bottom-1/3 hd:left-10`}
    >
      <img className="xs:w-36 xs:h-32 sm:w-40 sm:h-36 md:w-72 md:h-64 xl:w-40 xl:h-36 hd:w-56 hd:h-52 qhd:w-80 qhd:h-72 z-30" src="/images/de_molen.webp" alt="plus" />
      <img className="xs:w-32 xs:h-32   sm:w-36 sm:h-36 md:w-64 md:h-64 xl:w-36 xl:h-36 hd:w-52 hd:h-52 qhd:w-72 qhd:h-72 z-30" src="/images/bijlessr.webp" alt="De Molen" />
    </div>
  );
};

export default Logos;
