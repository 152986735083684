import { motion } from "framer-motion";
import React from "react";
import { FaTimesCircle } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import useEscape from "../../hooks/useEscape";

const Sidebar = ({ setMenuOpen, menuOpen }) => {
  useEscape(() => {
    setMenuOpen(false);
  });
  const sideMenu = {
    hidden: {
      x: -300,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      x: 0,
      transition: {
        duration: 0.2,
      },
    },
  };
  return (
    <motion.div variants={sideMenu} animate="show" exit="hidden" initial="hidden" className="h-screen xs:w-screen md:w-1/2 lg:w-1/3 fixed bg-white  z-50 shadow-2xl">
      <div className="flex flex-col  h-full  py-5  ">
        <div
          className="self-end  cursor-pointer pr-5 pb-5"
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        >
          <FaTimesCircle size={36} />
        </div>
        <NavLink
          onClick={() => setMenuOpen(false)}
          to="/"
          className="xs:text-xl xs:py-4 xs:px-5 border-t-2 border-b-2 border-gray-100 cursor-pointer font-bold hover:bg-black hover:text-white transition delay-50"
        >
          Home
        </NavLink>
        <NavLink
          onClick={() => setMenuOpen(false)}
          to="/klassen"
          className="xs:text-xl xs:py-4 xs:px-5 border-b-2 border-gray-100 cursor-pointer font-bold hover:bg-black hover:text-white transition delay-50"
        >
          Klassen
        </NavLink>
        <NavLink
          onClick={() => setMenuOpen(false)}
          to="/ons"
          className="xs:text-xl xs:py-4 xs:px-5 border-b-2 border-gray-100 cursor-pointer font-bold hover:bg-black hover:text-white transition delay-50"
        >
          Over Ons
        </NavLink>
        <NavLink
          onClick={() => setMenuOpen(false)}
          to="/contact"
          className=" xs:text-xl xs:py-4 xs:px-5  border-gray-100 cursor-pointer font-bold hover:bg-black hover:text-white transition delay-50"
        >
          Contact
        </NavLink>
        <div className="mt-auto  place-self-center">
          <img className="xs:h-16 w-content" src="/images/bijlessr.webp" alt="bijlessr " />
        </div>
        <div>
          <p className="text-center mb-10 xs:text-sm">
            Een <span className="font-bold">GWAP</span> dienst
          </p>
        </div>
      </div>
    </motion.div>
  );
};

export default Sidebar;
