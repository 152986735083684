import React from "react";
import Video from "../Video/Video";
import Loader from "../Loader/Loader";

const VideosList = ({ setCurrentVideo, setShowVideoPlayer, videos, videosLoading, videosError }) => {
  return (
    <div>
      <div
        className="z-40 absolute bottom-0 xs:inset-x-4 overscroll-auto scrollbar-hide shadow-xl h-auto  md:inset-x-8 overflow-scroll  lg:inset-x-10  xl:inset-x-64 2xl:inset-x-1/4 rounded-t-2xl
      lg:rounded-t-3xl
      xs:top-72 md:top-96 xl:top-72 2xl:top-1/3
       xs:w-11/12
      xl:w-7/12 2xl:w-1/2  bg-white xs:p-5 lg:p-10"
      >
        {videosLoading ? (
          <Loader />
        ) : videosError ? (
          <div>Iets is misgegaan</div>
        ) : videos.length === 0 ? (
          <div>Geen videos gevonden</div>
        ) : (
          <div className="grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 grid-auto-rows">
            {videos &&
              videos.map((video, index) => {
                return <Video key={index} setCurrentVideo={setCurrentVideo} video={video} setShowVideoPlayer={setShowVideoPlayer} />;
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default VideosList;
